<template>
  <v-container fluid>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <CandidateCard :uuid="$route.params.uuid" />
      </v-flex>

      <v-flex xs12>
        <router-view />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CandidateCard from '../../components/candidates/CandidateCard.vue';

export default {
  name: 'CandidatesDetail',
  components: {
    CandidateCard,
  },
};
</script>
